<template>
  <body-simple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <Icon name="Send"></Icon>
        تبلیغات سراسری : {{ ad.id }}
      </h2>
      <p class="mt-3">از این قسمت می‌توانید جزئیات سفارش خود را ببینید.</p>
    </template>
    <template #default>
      <div
        class="p-5 border-r-4 border-red-600 bg-red-100 text-red-900 rounded-sm"
        v-if="ad.status == 'incomplete'"
      >
        سفارش شما هنوز تکمیل نشده است
        <router-link
          :to="{
            name: 'GroupOrderEdit',
            params: { id: $store.state.user.id, adId: ad.id },
          }"
          class="text-sky-600 font-bold"
        >
          برای تکمیل اینجا کلیک کنید
        </router-link>
      </div>
      <div
        class="border rounded-md bg-blueGray-100 border-blueGray-200 flex flex-wrap mt-5"
      >
        <div class="w-full md:w-6/12 xl:w-5/12 p-3 lg:p-5">
          <div class="p-1 grid grid-cols-2 gap-1 even:bg-blueGray-200">
            <div class="">موضوع</div>
            <div class="font-bold text-left">{{ ad.type?.name ?? "سایر موارد" }}</div>
          </div>
          <div class="p-1 grid grid-cols-2 gap-1 even:bg-blueGray-200">
            <div class="">بازدید</div>
            <div class="font-bold text-left">{{ L_.sumBy(ad.views, "view") }}</div>
          </div>
          <div class="p-1 grid grid-cols-2 gap-1 even:bg-blueGray-200">
            <div class="">نوع ارسال</div>
            <div class="font-bold text-left">{{ ad.direct ? "مستقیم" : "معمولی" }}</div>
          </div>
          <div class="p-1 grid grid-cols-2 gap-1 even:bg-blueGray-200">
            <div class="">کلید تکی (جدا)</div>
            <div class="font-bold text-left">{{ ad.keys?.length ?? 0 }}</div>
          </div>
          <div class="p-1 grid grid-cols-2 gap-1 even:bg-blueGray-200">
            <div class="">کلید زیر بنر</div>
            <div class="font-bold text-left">{{ ad.sub_keys?.length ?? 0 }}</div>
          </div>
          <div class="p-1 grid grid-cols-2 gap-1 even:bg-blueGray-200">
            <div class="">تاریخ ثبت</div>
            <div class="font-bold text-left">{{ ad.created_at?.fa }}</div>
          </div>
          <div class="p-1 grid grid-cols-2 gap-1 even:bg-blueGray-200">
            <div class="">شروع</div>
            <div class="font-bold text-left">{{ ad.start_at?.fa }}</div>
          </div>
          <div class="p-1 grid grid-cols-2 gap-1 even:bg-blueGray-200">
            <div class="">پایان</div>
            <div class="font-bold text-left">{{ ad.end_at?.fa }}</div>
          </div>
          <div class="p-1 grid grid-cols-2 gap-1 even:bg-blueGray-200">
            <div class="">وضعیت</div>
            <div class="font-bold text-left">{{ list_status[ad.status] }}</div>
          </div>
        </div>
        <div class="w-full md:w-6/12 xl:w-7/12 p-3 lg:p-5">
          <div class="p-1">
            نوع بنر :
            <span class="font-bold">
              {{ ad.file_type ? list_type[ad.file_type] ?? "سایر" : "نامشخص" }}
            </span>
          </div>
          <div class="p-1">
            <hr class="border-coolGray-200 my-3" />
            متن بنر:
            <div>
              {{ ad.text ?? "مشخص نشده" }}
            </div>
            <hr class="border-coolGray-200 my-3" />
            <template v-if="ad.sub_keys?.length">
              کلید های زیر بنر:
              <div
                class="p-1 grid grid-cols-2 gap-1 even:bg-blueGray-200"
                v-for="item in ad.sub_keys"
                :key="item.id"
              >
                <div class="">{{ item.text }}</div>
                <div class="font-bold text-left">{{ item.link }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div
        class="border rounded-md bg-blueGray-100 border-blueGray-200 mt-5 p-3 lg:p-5"
        v-if="ad.keys?.length"
      >
        <h2 class="font-bold text-lg mb-3">کلید ها</h2>
        <div class="flex flex-wrap py-2 bg-teal-600 text-teal-50">
          <div class="sm:w-3/12 w-full font-bold px-3 py-1">متن کلید</div>
          <div class="sm:w-3/12 w-full font-bold px-3 py-1">لینک کلید</div>
          <div class="sm:w-3/12 w-full font-bold px-3 py-1">تعدا ارسال</div>
          <div class="sm:w-3/12 w-full font-bold px-3 py-1">تاریخ</div>
        </div>
        <div
          class="flex flex-wrap py-2 even:bg-blueGray-200"
          v-for="item in ad.keys"
          :key="item.id"
        >
          <div class="sm:w-3/12 w-full px-3 py-1">{{ item.text }}</div>
          <div class="sm:w-3/12 w-full px-3 py-1">{{ item.link }}</div>
          <div class="sm:w-3/12 w-full px-3 py-1">{{ item.send }}</div>
          <div class="sm:w-3/12 w-full px-3 py-1">{{ item.date?.fa }}</div>
        </div>
      </div>
    </template>
  </body-simple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import _ from "lodash";

export default {
  components: {
    Icon,
    BodySimple,
  },
  data() {
    return {
      firstLoading: true,
      ad: {},
      errors: [],
      L_: _,
      list_status: {
        end: "تمام شده",
        running: "در حال اجرا",
        wait: "در انتظار",
        incomplete: "نیاز به تکمیل",
      },
      list_type: {
        photo: "عکس",
        video: "فیلم",
        text: "متن",
      },
    };
  },
  mounted() {
    let $this = this;
    $this.errors = {};

    $this.$axios
      .get("/api/ad-groups/" + $this.$route.params.adId, {
        params: {
          id: $this.$store.state.user.id,
        },
      })
      .then(function (response) {
        $this.ad = response.data.ad;
      })
      .catch(function (error) {
        $this.errors = error.data;
      })
      .finally(function () {
        $this.firstLoading = false;
      });
  },
};
</script>
